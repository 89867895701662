:root {
	background-color: #000 !important;
}

body {
	background-color: #000
}

#console {
	font-family: courier, monospace;
	color: #fff;
	margin-left:auto;
	margin-right:auto;
	margin-top:100px;
	font-size:14px;
}

a {
	color: #0bc;
	text-decoration: none;
}

#a {
	color: #0f0;
}

#c {
	color: #0bc;
}

#b {
	color: #ff0096;
}

#k {
    	animation: change 1s;
}

#op{
    	color: #888888
}

@keyframes change {
    	0%  { color:  #0f0; }
    	50% { color:  #0f0; }
    	99% { color: black; }
}
